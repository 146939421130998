import { view } from "@risingstack/react-easy-state";
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Select from 'react-select';
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer'
import request from "../../../helpers/request";
import endpoints from "../../../helpers/endpoints";
import ReactModal from 'react-modal'
import { DatePicker } from '@material-ui/pickers';
import Pagination from '../../../components/pagination/pagination';
import { createMeasureOptions } from "../AutomatedAddressCleanse/shared";

export default view(function DataCleanseValidationAssurance() {
    const [users, setUsers] = useState([])
    const [workflowItems, setWorkflowItems] = useState([])
    const [loadingWorkflow, setLoadingWorkflow] = useState(false)

    const earlierDate = new Date();
    earlierDate.setDate(earlierDate.getDate() - 7);
    earlierDate.setHours(0, 0, 0, 0);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const [fromTime, setFromTime] = useState(earlierDate);
    const [toTime, setToTime] = useState(currentDate);
    const [selectedUser, setSelectedUser] = useState()
    const [selectedMeasure, setSelectedMeasure] = useState({})
    const [measureExplanations, setMeasureExplanations] = useState([])
    const [selectedMeasureExplanation, setSelectedMeasureExplanation] = useState()
    const [selectedStatus, setSelectedStatus] = useState()
    const [selectedModule, setSelectedModule] = useState();
    const [page, setPage] = useState()
    const [lastPage, setLastPage] = useState()
    const [search, setSearch] = useState('')
    const [commentModalSettings, setCommentModalSettings] = useState({isOpen: false, measureType: '', measureComment: ''})
    const table = useRef(null);

    const modules = [
        { label: 'All Modules', value: '' },
        { label: 'Automated Address Cleanse', value: 'AUTOMATED_ADDRESS_CLEANSE' },
        { label: 'Invalid Postcode Street', value: 'INVALID_POSTCODE_STREET' },
        { label: 'Invalid UPRN/VOA Ref In CMOS', value: 'INVALID_UPRN_VOA_CMOS' },
        { label: 'Meter Coordinates Validation', value: 'METER_COORDINATION_VALIDAITON' },
        { label: 'UPRN/VOA Address Conflicts', value: 'INVALID_UPRN_VOA' },
        { label: 'Undeliverable', value: 'UNDELIVERABLE' },
    ]
    const measures = [{label: 'All Measures', value: ''}, { label: 'UPRN', value: 'UPRN' }, { label: 'VOA', value: 'VOA' }, { label: 'LTV', value: 'LTV' }, { label: 'VWC', value: 'VWC' }];

    useEffect(() => {
        const measureOptions = createMeasureOptions(selectedMeasure.value)
        if (selectedMeasure.value === "UPRN" || selectedMeasure.value === "VOA") {
            setMeasureExplanations(measureOptions.uprn_voa_options)
        } else if (selectedMeasure.value === "LTV") {
            setMeasureExplanations(measureOptions.ltv_options)
        } else if (selectedMeasure.value === "VWC") {
            setMeasureExplanations(measureOptions.vwc_options)
        }
    }, [selectedMeasure])

    const getWorkflowItems = () => {
        if (!selectedUser) {
            setPage()
            return
        }
        setLoadingWorkflow(true)
        const fromTimeUTC = fromTime.toISOString().slice(0, 10);
        const toTimeUTC = toTime.toISOString().slice(0, 10);
        request(true).get(endpoints.DATA_CLEANSE_VALIDATION_ASSURANCE_ITEMS + '?page=' + page, {
            doesCancel: true,
            params: {
                spid: search,
                user: selectedUser?.value,
                module: selectedModule?.value,
                fromTime: fromTimeUTC,
                toTime: toTimeUTC,
                ...(selectedStatus && { selectedStatus: selectedStatus?.value }),
                ...(selectedMeasure && { measure: selectedMeasure.value }),
                ...(selectedMeasureExplanation && { measureExplanation: selectedMeasureExplanation }),
            }
        }).then(e => {
            setWorkflowItems(e.data.data ?? []);
            setLoadingWorkflow(false);
            if (e.data.current_page !== page) {
                setPage(e.data.current_page)
            }
            setLastPage(e.data.last_page)
        }).catch(e => {
            console.log(e);
            window.alert('Could not retrieve data')
            setLoadingWorkflow(false)
        })
    }

    useEffect(() => {
        request(true).get(endpoints.DATA_CLEANSE_VALIDATION_QA_USERS).then(r => {
            setUsers(Object.keys(r.data).map(key => { return { label: r.data[key], value: key } }))
        })
    }, [])

    useEffect(() => {
        if (!selectedUser) return
        setPage(0)
        setSearch(null)
    }, [selectedUser, selectedModule, selectedStatus, fromTime, toTime, selectedMeasure, selectedMeasureExplanation])

    useEffect(() => {
        if (page === 0) {
            setPage(1)
        } else {
            getWorkflowItems()
        }
    }, [page])

    useEffect(() => {
        if (search !== null) {
            getWorkflowItems()
        }
    }, [search])

    return (
        <GenericPageContainer
            className={'DCV-Assurance'}
            containerWidth={"95vw"}
            title="Data Cleanse Validation - Assurance"
            titleIconClass={"fak fa-id-business-icon fa-2x colour-primary"}
            titleRight={
                <div>
                    <Link className="navigate" to="/portal/data-cleanse-validation">
                        To Summary
                    </Link>
                    <Link className="navigate" to="/portal/data-cleanse-validation/quality-assurance">
                        To Quality Assurance
                    </Link>
                </div>
            }
        >
            <div class="quality-assurance" style={{ gridTemplateColumns: '1fr 1fr 1fr 1fr', marginBottom: '10px' }}>
                <div>
                    <p className="font-weight-600">User</p>
                    <Select
                        options={[{ label: 'All Users', value: '' }].concat(users)}
                        onChange={setSelectedUser}
                        styles={{
                            container: (baseStyles, state) => ({
                                ...baseStyles,
                                display: 'grid'
                            }),
                        }}
                    />
                </div>
                <div>
                    <p className="font-weight-600">Module</p>
                    <Select
                        options={modules}
                        onChange={setSelectedModule}
                        defaultValue={{ label: 'All Modules', value: '' }}
                        styles={{
                            container: (baseStyles, state) => ({
                                ...baseStyles,
                                display: 'grid'
                            }),
                        }}
                    />
                </div>
                <div>
                    <p className="font-weight-600">Measure</p>
                    <Select
                        options={measures}
                        onChange={setSelectedMeasure}
                        defaultValue={{ label: 'All Measures', value: '' }}
                        styles={{
                            container: (baseStyles, state) => ({
                                ...baseStyles,
                                display: 'grid'
                            }),
                        }}
                    />
                </div>
                <div>
                    <p className="font-weight-600">Explanation</p>
                    <Select
                        options={measureExplanations}
                        onChange={setSelectedMeasureExplanation}
                        defaultValue={{ label: 'All Measures', value: '' }}
                        styles={{
                            container: (baseStyles, state) => ({
                                ...baseStyles,
                                display: 'grid'
                            }),
                        }}
                    />
                </div>
                <div>
                    <p className="font-weight-600">From</p>
                    <DatePicker variant="inline" label="From:" value={fromTime} onChange={setFromTime} />
                </div>
                <div>
                    <p className="font-weight-600">To</p>
                    <DatePicker variant="inline" label="To:" value={toTime} onChange={setToTime} />
                </div>
                <div>
                    <p className="font-weight-600">Status</p>
                    <Select
                        options={[{ label: 'All Statuses', value: '' }, { label: 'Assured', value: 'Assured' }, { label: 'Quality Check Passed', value: 'Quality Check Passed' }]}
                        onChange={setSelectedStatus}
                        defaultValue={{ label: 'All Statuses', value: '' }}
                        styles={{
                            container: (baseStyles, state) => ({
                                ...baseStyles,
                                display: 'grid'
                            }),
                        }}
                    />
                </div>
                <div>
                    <p className="font-weight-600">Core SPID</p>
                    <input type="text" onChange={e => setSearch(e.target.value)} value={search ?? ''} placeholder="Search..." style={{ height: '100%', alignSelf: 'end' }} />
                </div>
            </div>
            <table ref={table} className='table borders squish smaller-text'>
                <thead>
                    <tr>
                        <th>Core SPID</th>
                        <th>Module</th>
                        <th>Status</th>
                        <th>User</th>
                        <th>Insert Date</th>
                        <th>UPRN Explanation</th>
                        <th>VOA Explanation</th>
                        <th>LTV Explanation</th>
                        <th>VWC Explanation</th>
                    </tr>
                </thead>
                <tbody>
                    { loadingWorkflow ? (
                        <tr>
                            <td colSpan="11">
                                <i className="fa fa-spinner fa-spin" />
                            </td>
                        </tr>
                    ) : workflowItems.length > 0 ? (
                        workflowItems.map(row => (
                            <tr key={row.Core_SPID}>
                                <td>{row.Core_SPID}</td>
                                <td>{modules.find(module => module.value === row.Module)?.label}</td>
                                <td>{row.Status}</td>
                                <td>{users.find(user => user.value === row.User_ID)?.label}</td>
                                <td>{row.Insert_Date}</td>
                                {
                                    ['UPRN', 'VOA'].map(measure => {
                                        return (
                                            row[`${measure}_Measure`] === "Verified Non-Addressable: Other (Please Comment)" ?
                                                <td 
                                                className="measure-comment-link"
                                                onClick={() => {
                                                    setCommentModalSettings({isOpen: true, measureComment: row[`${measure}_Measure_Comment`], measureType: `${measure} Measure Comment`})
                                                }}>
                                                    {row[`${measure}_Measure`]}
                                                </td>
                                            :
                                                <td>
                                                    {row[`${measure}_Measure`]}
                                                </td>
                                        )
                                    })
                                }
                                <td>{row.LTV_Measure}</td>
                                <td>{row.VWC_Measure}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            {Array.from(table.current?.rows[0].cells ?? []).map((cell, index) => (
                                <td key={index}></td>
                            ))}
                        </tr>
                    )}
                </tbody>
            </table>
            { workflowItems.length > 0 && lastPage > 1 &&
                <Pagination page={page} pages={lastPage} pageChange={(page) => setPage(page)}/>
            }
            <ReactModal 
            isOpen={commentModalSettings.isOpen} 
            onRequestClose={() => setCommentModalSettings({...commentModalSettings, isOpen: false})}
            className="card bulk-allocate"
            contentLabel="Measure Comment"
            style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' }, content: { position: 'relative' } }}>
                <div style={{ position: 'absolute', right: '1em', top: '0.5em', cursor: 'pointer' }} onClick={() => setCommentModalSettings({...commentModalSettings, isOpen: false})}>
                    <i class="fa-solid fa-xmark"></i>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
                    <h3>{commentModalSettings.measureType}</h3>
                    <p>{commentModalSettings.measureComment}</p>
                </div>
            </ReactModal>
        </GenericPageContainer>
    )
})