import React, { useEffect, useState } from "react";
import ProductDataFilter from "../../../components/product-data-filter/product-data-filter";
import GenericDataChart from "../../../GenericPageElements/GenericDataChart";
import GenericPageContainer from "../../../GenericPageElements/GenericPageContainer";
import GenericProductFilterContainer from "../../../GenericPageElements/GenericProductFilterContainer";
import GenericDataRow from "../../../GenericPageElements/GenericDataRow";
import endpoints from "../../../helpers/endpoints";
import hasPermission from '../../../helpers/permissions';
import request from "../../../helpers/request";
import S3Modal from "../../../components/s3Modal/S3Modal";
import { view } from "@risingstack/react-easy-state";
import { Link } from "react-router-dom";
import Modal from 'react-modal';
import { CustomizedAxisTickMultiLine } from "../CompaniesHouseAlerts/CompaniesHouseAlerts";
import './DataCleanseValidation.scss';

export default view(function DataCleanseValidation() {
    const [filters, setFilters] = useState({});
    const [selectedGroupBy, setSelectectGroupBy] = useState("Status");
    const [data, setData] = useState(null);
    const [dates, setDates] = useState([]);
    const [date, setDate] = useState(null);
    const [S3Model, setS3Model] = useState(null);
    const [bulkModel, setBulkModel] = useState('')
    const [createDownloadLoading, setCreateDownloadLoading] = useState(false);
    const [createDownloadError, setCreateDownloadError] = useState();

    const applyFilter = (column, value) => {
        setFilters((current) => {
          return {
            ...current,
            [column]: value,
          };
        });
    };

    const createDownloadFile = () => {
      setCreateDownloadLoading(true)
      request(true).post(endpoints.DATA_CLEANSE_VALIDATION_BULK_BASE, {
        filter: filters,
        fileType: bulkModel
      }).then(r => {
        window.location.reload();
      }).catch(error => {
        console.error(error)
        setCreateDownloadLoading(false)
        setCreateDownloadLoading("Failed to create download file.")
      })
    }

    const closeModal = () => {
      setS3Model(false)
      setBulkModel(false)
    }

    useEffect(() => {
        request(true)
          .get(endpoints.DATA_CLEANSE_VALIDATION_DATES)
          .then((e) => {
            setDates(e.data.dates);
            // setDate(e.data.dates[0])
            // if (e.data.dates.length > 0) setDate("All_Refreshes");
          });
    }, []);

    useEffect(() => {
        // if (!date) return;
        request(true)
          .get(endpoints.DATA_CLEANSE_VALIDATION_DATA, {
            doesCancel: true,
            params: {
              ...(date && {date: date}),
              filter: filters,
              group: selectedGroupBy,
            },
          })
          .then((e) => {
            setData(e.data);
          });
          if (!filters['Status'] || filters['Status'].length < 1) {
            setCreateDownloadError("Please select a status from the filter menu.")
          } else {
            setCreateDownloadError()
          }
      }, [filters, selectedGroupBy, date]);

    return [
        <GenericPageContainer
            title="Data Cleanse Validation"
            titleIconClass={"fak fa-id-business-icon fa-2x colour-primary"}
            containerWidth={'92vw'}
            titleRight={
            <div>
                {hasPermission('DATA_CLEANSE_VALIDATION', 'WORKFLOW') ? (
                    <Link className="navigate" to="/portal/data-cleanse-validation/quality-assurance">
                      Quality Assurance
                    </Link>
                ) : null}
                {hasPermission('DATA_CLEANSE_VALIDATION', 'WORKFLOW') ? (
                    <Link className="navigate" to="/portal/data-cleanse-validation/assurance">
                      Assurance
                    </Link>
                ) : null}
                {hasPermission('DATA_CLEANSE_VALIDATION', 'DOWNLOAD') ? (
                    <button className="navigate" onClick={() => setS3Model("download")}>
                        Download Return File
                    </button>
                ) : null}
            </div>
            }
        >
          <div style={{maxWidth: '20vw', marginBottom: 15}}>
              <GenericDataRow title={"File Date"} contentColour={"white"}>
                  <select
                  onChange={(_) => setDate(_.target.value)}
                  value={date}
                  style={{
                      width: "100%",
                      height: "100%",
                      border: "none",
                      backgroundColor: "white",
                      paddingLeft: "10px",
                  }}
                  >
                  {/* <option key={"All_Refreshes"} value={"All_Refreshes"}>All Refreshes</option> */}
                  {dates.map((item) => {
                      return (
                      <option key={item.raw} value={item.raw}>
                          {item.pretty}
                      </option>
                      );
                  })}
                  </select>
              </GenericDataRow>
          </div>
          <div className='DCV'>
            <GenericDataChart
              dataReady={!!data}
              data={data}
              selectedGroupBy={selectedGroupBy}
              setSelectectGroupBy={setSelectectGroupBy}
              groupingsEndpoint={endpoints.DATA_CLEANSE_VALIDATION_GROUPINGS}
              sizes={{chart: 600, chartContainer: 625, chartXAxisFontSize: 12, wrapperStyle: { paddingTop: '40px' }}}
              xAxisProps={{interval: 0}}
              customBarTick={CustomizedAxisTickMultiLine}
              overrideTableHeader={"Summary"}
              removeTableHeader={true}
              subHeaders={[{source: 'valid', header: 'Valid'}, {source: 'table_insert', header: 'To Be Worked'}]}
              hideGroups={true}
            />
            <GenericProductFilterContainer clearFilters={() => setFilters({})}>
              <ProductDataFilter
                filterState={filters}
                applyFilter={applyFilter}
                dataFilterEndpoint={endpoints.DATA_CLEANSE_VALIDATION_FILTERS}
              />
              <div className='grid grid-gap-5 mta'>
                  { (hasPermission('DATA_CLEANSE_VALIDATION', 'BULK-BUY')) ?
                      <button className="button bulk-buy-button compact smaller-text" onClick={() => setBulkModel('change')}>
                          Download Change Files
                      </button>
                  : '' }
                  { (hasPermission('DATA_CLEANSE_VALIDATION', 'BULK-BUY')) ?
                      <button className="button bulk-buy-button compact smaller-text" onClick={() => setBulkModel('assured')}>
                          Download Assurance Files
                      </button>
                  : '' }
              </div>
            </GenericProductFilterContainer>
          </div>
        </GenericPageContainer>,
        <S3Modal
          closeModal={ closeModal }
          modalMode={S3Model}
          showModal={!!S3Model}
          listingUrl={endpoints.DATA_CLEANSE_VALIDATION_FILES}
          uploadUrl={endpoints.DATA_CLEANSE_VALIDATION_FILES}
        />,
        <Modal
          style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
          className="card bulk-buy"
          isOpen={bulkModel}
          onRequestClose={closeModal}
          contentLabel="Bulk Buy DCV Change Files"
          appElement={document.getElementById('root')}
        >
          <div className='bbmodal-text'>
            These files include data based on the filters you've applied. If you're satisfied with your selection, click "Confirm" to proceed.
            {createDownloadError ? <p className="colour-yellow"><strong>{createDownloadError}</strong></p> : null} 
            <div className="grid-2">
                <button className="button cancel" onClick={ closeModal }>Cancel</button>
                <button className="button confirm" onClick={ () => createDownloadFile() } disabled={ createDownloadError }>
                    {
                        createDownloadLoading ?
                            <div className="spinner">
                                <i className="fa fa-spinner fa-spin"></i>
                            </div>
                        : 'Confirm'
                    }
                </button>
            </div>
          </div>
        </Modal>
    ]
})
